import { TitleBackgroundVariants } from "../../components/titleContainerSelectbox/TitleContainerSelectbox";
import { ProjectTabEnum } from "../../masks/public/projectData/ProjectTabEnum";
import IProjectFull from "../../types/project/IProjectFull";
import { IProjectHeaderData } from "./PortalHeader";

const getEither = <OUT = string>(content: OUT | undefined, fallbackContent: OUT | undefined): OUT => {
    if (content != null && (content as unknown as string) != "") {
        return content;
    }

    return String(fallbackContent) as unknown as OUT;
};

const getProjectHeaderData = (activeProject: IProjectFull, activeTab?: string): IProjectHeaderData => {
    // active tab might be empty
    const theTab = activeTab ? activeTab : ProjectTabEnum.START_TAB;

    switch (theTab) {
        case ProjectTabEnum.START_TAB: {
            return {
                title: activeProject?.titleTabStart,
                desc: activeProject?.descTabStart,
                header: activeProject?.headerTabStart ? activeProject?.headerTabStart : activeProject?.imageHeader,
                titleBackground: activeProject?.customFields?.headerBgProject,
            };
        }
        case ProjectTabEnum.INFO_TAB: {
            return {
                title: activeProject?.titleTabInfo,
                desc: activeProject?.descTabInfo,
                header: activeProject?.headerTabInfo,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabInfo,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        case ProjectTabEnum.EVENTS_TAB: {
            return {
                title: getEither(activeProject?.titleTabEvents, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabEvents, activeProject.descTabStart),
                header: activeProject?.headerTabEvents ? activeProject?.headerTabEvents : activeProject?.imageHeader,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabEvents,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        case ProjectTabEnum.PARTICIPATE_TAB: {
            return {
                title: getEither(activeProject?.titleTabParticipate, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabParticipate, activeProject.descTabStart),
                header: activeProject?.headerTabParticipate
                    ? activeProject?.headerTabParticipate
                    : activeProject?.imageHeader,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabParticipants,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        case ProjectTabEnum.TEAM_TAB: {
            return {
                title: getEither(activeProject?.titleTabTeam, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabTeam, activeProject.descTabStart),
                header: activeProject?.headerTabTeam ? activeProject?.headerTabTeam : activeProject?.imageHeader,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabTeam,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        case ProjectTabEnum.CONSULTING_TAB: {
            return {
                title: getEither(activeProject?.titleTabConsulting, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabConsulting, activeProject.descTabStart),
                header: activeProject?.headerTabConsulting
                    ? activeProject?.headerTabConsulting
                    : activeProject?.imageHeader,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabConsulting,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        case ProjectTabEnum.CAMPAIGN_TAB: {
            return {
                title: getEither(activeProject?.titleTabCampaign, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabCampaign, activeProject.descTabStart),
                header: activeProject?.headerTabCampaign
                    ? activeProject?.headerTabCampaign
                    : activeProject?.imageHeader,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabCampaign,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        case ProjectTabEnum.RESULTS_TAB: {
            return {
                title: getEither(activeProject?.titleTabResults, activeProject?.titleTabStart),
                desc: getEither(activeProject?.descTabResults, activeProject.descTabStart),
                header: activeProject?.headerTabResults ? activeProject?.headerTabResults : activeProject?.imageHeader,
                titleBackground: getEither<TitleBackgroundVariants>(
                    activeProject?.customFields?.headerBgTabResults,
                    activeProject?.customFields?.headerBgTabStart
                ),
            };
        }
        default: {
            return {
                title: activeProject?.titleTabStart,
                desc: activeProject?.descTabStart,
                header: activeProject?.headerTabStart ? activeProject?.headerTabStart : activeProject?.imageHeader,
                titleBackground: activeProject?.customFields?.headerBgTabStart,
            };
        }
    }
};

export default getProjectHeaderData;
