import React from "react";
import { useHistory } from "react-router-dom";

import RoutesEnum from "../../../../routes/RoutesEnum";
import IMandant from "../../../../types/IMandant";
import InitialProjectFullData from "../../../../types/initialData/InitialProjectFullData";
import InitialSnackbarState from "../../../../types/initialData/InitialSnackbarState";
import IProjectGalleryImage from "../../../../types/IProjectGalleryImage";
import ISnackbarState from "../../../../types/ISnackbarState";
import IProjectFull from "../../../../types/project/IProjectFull";
import IProjectResult from "../../../../types/project/IProjectResult";
import deepCopy from "../../../../utils/deepCopy";
import DevLogger from "../../../../utils/logger/DevLogger";
import { toPortalUrl } from "../../../../utils/toPortalUrl";
import {
    adminGetSingleProjectRequest,
    createProjectRequest,
    loadProjectAttachmentsRequest,
    loadProjectProjectRequest,
    updateProjectRequest,
} from "../../../../xhr/ProjectRequests";
import {
    IProjectValidationState,
    IProjectValidationStateWithDocumentAndImage,
    requiredProjectDataFilled,
    requiredProjectResulatsDataFilled,
} from "../projectDataValidation";

export interface ILocalImageGallery {
    [key: string]: IProjectGalleryImage[];
}

export interface UseProjectCrudLogicParams {
    datasetId: number;
    mandant: IMandant;
}

export function useProjectCrudLogic({ datasetId, mandant }: UseProjectCrudLogicParams) {
    const history = useHistory();

    const [activeTab, setActiveTab] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isEditForm, setIsEditForm] = React.useState<boolean>(!isNaN(datasetId));

    const [snackBarState, setSnackBarState] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [changesNotSavedState, setChangesNotSavedState] = React.useState<boolean>(false);

    const [projectData, setProjectData] = React.useState<IProjectFull>({
        ...InitialProjectFullData,
    });

    const [showDoku, setShowDoku] = React.useState<boolean>(false);

    const [projectZoom, setProjectZoom] = React.useState<number>(4);

    const [errorState, setErrorState] = React.useState<IProjectValidationState>({ hasError: false });
    const [projectResultsErrorState, setProjectResultsErrorState] = React.useState<
        IProjectValidationStateWithDocumentAndImage[]
    >([]);

    /**
     * Gallery states
     */
    const [images, setImages] = React.useState<ILocalImageGallery>({});
    const [projectResults, setProjectResults] = React.useState<IProjectResult[]>([]);

    const [galleryLoading, setGalleryLoading] = React.useState<boolean>(false);

    const closeChangesNotSavedWarning = () => {
        setChangesNotSavedState(false);
    };
    const openChangesNotSavedWarning = () => {
        setChangesNotSavedState(true);
    };

    /* load project data to state */
    const loadProjectToState = (projectId: string) => {
        setLoading(true);
        adminGetSingleProjectRequest(mandant.key, projectId)
            .then((response) => {
                setIsEditForm(true);
                setProjectZoom(response.data.zoom);
                setProjectData(response.data);

                loadProjectAttachmentsRequest(mandant.key, String(datasetId)).then((res) => {
                    images.attachments = res.data ? [...res.data] : [];
                    images.default = response.data.images ? [...response.data.images] : [];

                    setImages(deepCopy<ILocalImageGallery>(images));
                });

                loadProjectProjectRequest(mandant.key, String(datasetId)).then((res) => {
                    setProjectResults(deepCopy<IProjectResult[]>(res.data ? [...res.data] : []));
                });
                closeChangesNotSavedWarning();
                setLoading(false);
            })
            .catch(() => {
                setSnackBarState({
                    isOpen: true,
                    message: "Beim Laden der Projektdaten ist ein Fehler aufgetreten.",
                    type: "error",
                });
                setLoading(false);
            });
    };
    /* load files attachments to state */

    const handleProjectSave = () => {
        const theValidation = requiredProjectDataFilled(projectData);
        const theProjectResultValidation = requiredProjectResulatsDataFilled(projectResults);
        const validation = {
            ...theValidation,
            hasError: theValidation.hasError,
        };
        setErrorState(validation);
        setProjectResultsErrorState(theProjectResultValidation);

        if (validation.hasError || Boolean(theProjectResultValidation.length)) {
            DevLogger.logError("errors in the project", {
                errors: theValidation,
            });
            setSnackBarState({
                isOpen: true,
                message: "Fehlende Pflichtfelder.",
                type: "error",
            });
            return;
        }

        setLoading(true);

        if (isEditForm) {
            updateProjectRequest(mandant.key, {
                ...projectData,
                zoom: projectZoom,
            })
                .then(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Das Projekt wurde erfolgreich gespeichert.",
                        type: "success",
                    });
                    setLoading(false);
                    closeChangesNotSavedWarning();
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Speichern der Projektdaten ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setLoading(false);
                });
        } else {
            createProjectRequest(mandant.key, projectData)
                .then((response: any) => {
                    history.push(toPortalUrl(mandant, RoutesEnum.ADMIN_PROJECT + response.data.id));

                    setIsEditForm(true);
                    loadProjectToState(response.data.id);

                    setSnackBarState({
                        isOpen: true,
                        message: "Das Projekt wurde erfolgreich erstellt.",
                        type: "success",
                    });
                    closeChangesNotSavedWarning();
                    setLoading(false);
                })
                .catch(() => {
                    setSnackBarState({
                        isOpen: true,
                        message: "Beim Erstellen des Projektes ist ein Fehler aufgetreten",
                        type: "error",
                    });
                    setLoading(false);
                });
        }
    };

    /**
     *
     */
    const closeSnackbar = () => {
        setSnackBarState({ isOpen: false, message: "", type: "success" });
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setActiveTab(newValue);
    };

    React.useEffect(() => {
        if (isEditForm) {
            loadProjectToState(datasetId.toString());
        }
    }, [isEditForm]);

    return {
        activeTab,
        setActiveTab,
        loading,
        setLoading,
        isEditForm,
        setIsEditForm,
        snackBarState,
        setSnackBarState,
        changesNotSavedState,
        setChangesNotSavedState,
        projectData,
        setProjectData,
        showDoku,
        setShowDoku,
        projectZoom,
        setProjectZoom,
        errorState,
        setErrorState,
        projectResultsErrorState,
        setProjectResultsErrorState,
        images,
        setImages,
        projectResults,
        setProjectResults,
        galleryLoading,
        setGalleryLoading,
        handleProjectSave,
        closeChangesNotSavedWarning,
        openChangesNotSavedWarning,
        loadProjectToState,
        closeSnackbar,
        handleTabChange,
    };
}
