import { Checkbox, Divider, FormGroup, Grid, Paper, TextField } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import { TitleContainerSelectbox } from "../../../../components/titleContainerSelectbox/TitleContainerSelectbox";
import TextEdit from "../../../../components/wysiwyg/TextEdit";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/project/IProjectFull";
import MapProposalTypeEditor from "../components/mapProposalTyp/MapProposalTypeEditor";

type IParticipationDescTabProps = {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    handleTextCustomFieldsChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    onCheckboxChange: (event: any) => void;
    handleCheckboxCustomFieldsChange: (event: any) => void;
    projectData: IProjectFull;
    onChangeWysiwyg: (editorState: any, name: string) => void;
    showDocu: boolean;
};

const ParticipationDescTab = (props: IParticipationDescTabProps) => {
    const {
        mandant,
        projectData,
        onChangeWysiwyg,
        onCheckboxChange,
        onImageChange,
        onTextChange,
        handleCheckboxCustomFieldsChange,
        handleTextCustomFieldsChange,
        showDocu,
    } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Beteiligung Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden, werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="menuTabParticipate"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabParticipate}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TextField
                        name="titleTabParticipate"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabParticipate}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TitleContainerSelectbox
                        selectedValue={projectData.customFields?.headerBgTabParticipants || ""}
                        name={"headerBgTabParticipants"}
                        setSelectedValue={handleTextCustomFieldsChange}
                    />
                    <TextField
                        name="descTabParticipate"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabParticipate}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabParticipate}
                        idKey={"headerTabParticipate"}
                        name={"headerTabParticipate"}
                        label={"Info Header"}
                        defaultFilename="Header.jpg"
                        minSourceImageWidth={2000}
                        minSourceImageHeight={1125}
                        imageExportHeightInPixels={1125}
                        imageExportWidthInPixels={2000}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Beteiligung Inhalt
                    </Typography>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={6}>
                        <TextEdit
                            handleChange={(editorState) => {
                                onChangeWysiwyg(editorState, "mapProposalText");
                            }}
                            showHelp={showDocu}
                            title="Beschreibung Mitmachen"
                            defaultValue={props.projectData.mapProposalText ? projectData.mapProposalText : ""}
                        />

                        <TextEdit
                            handleChange={(editorState) => {
                                onChangeWysiwyg(editorState, "commmentRulesText");
                            }}
                            showHelp={showDocu}
                            title="Kommentar Regeln"
                            defaultValue={projectData.commmentRulesText ? projectData.commmentRulesText : ""}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <FormLabel component="legend">Karten Einstellungen</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagMapComments}
                                        onChange={onCheckboxChange}
                                        name="flagMapComments"
                                    />
                                }
                                label="Mitmachen / Kartenkommentare aktivieren"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagMapCommentsReadonly}
                                        onChange={onCheckboxChange}
                                        name="flagMapCommentsReadonly"
                                    />
                                }
                                label="Mitmachen Karte ist nur Lesbar"
                            />
                        </FormGroup>

                        <MapProposalTypeEditor projectId={projectData.id} mandant={mandant} />

                        <Divider className={"gap-bottom gap-top"} />

                        <FormLabel component="legend">Kommentar Einstellungen</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagCommentsOpen}
                                        onChange={onCheckboxChange}
                                        name="flagCommentsOpen"
                                    />
                                }
                                label="Kommentare für das Projekt aktiv"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagCommentsReadonly}
                                        onChange={onCheckboxChange}
                                        name="flagCommentsReadonly"
                                    />
                                }
                                label="Kommentare auf Readonly stellen"
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!projectData.flagCommentsRegistered}
                                        onChange={onCheckboxChange}
                                        name="flagCommentsRegistered"
                                    />
                                }
                                label="Nur registrierte Benutzer dürfen Kommentare schreiben"
                            />

                            <FormControlLabel
                              control={
                                  <Checkbox
                                    checked={!!projectData.customFields?.showCommentHeaderCounter}
                                    onChange={handleCheckboxCustomFieldsChange}
                                    name="showCommentHeaderCounter"
                                  />
                              }
                              label="Position des Kommentars statt Datum anzeigen"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ParticipationDescTab;
