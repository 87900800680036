import "../../../components/cards/Cards.css";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

import { API_URL_STATIC } from "../../../config/config";
import IProjectResult from "../../../types/project/IProjectResult";
import stringIsEmpty from "../../../utils/form/stringIsEmpty";
import ButtonAsLink from "./../../buttonAsLink/ButtonAsLink";

interface IEventTileParams extends IProjectResult {}

const ResultTile = (props: IEventTileParams) => {
    return (
        <Grid xs={12} sm={12} md={6} lg={6} item>
            <Card
                variant="outlined"
                className={"with-shadow rounded-bottom-only tile__card full-height-card"}
                data-id={props.id}
            >
                <CardActionArea disableRipple={true}>
                    <CardMedia
                        style={{ height: "200px" }}
                        image={API_URL_STATIC + props.previewImage.url}
                        title={props.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h2" component="h2">
                            {props.title}
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" component="p" align="left">
                            {props.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <ButtonAsLink
                        linkUrl={API_URL_STATIC + props.document.url}
                        actionButtonText={!stringIsEmpty(props.linkTitel) ? props.linkTitel : "Das Dokument öffnen"}
                    />
                </CardActions>
            </Card>
        </Grid>
    );
};

export default ResultTile;
