import React, { useCallback } from "react";
import uuid from "uuid-random";

import DocumentTypeEnum from "../../../../../enums/DocumentTypeEnum";
import IDocument, { DocumentIndex, DocumentIndexSetter } from "../../../../../types/IDocument";
import IDocumentRef from "../../../../../types/IDocumentRef";
import { CmsPageData, CmsPageProperties } from "../../../types/CmsPageData";

export function useChangeDataLogic({
    pageData,
    setPageData,
    imagesIndex,
    setImagesIndex,
    setFormHasChangesAction,
}: {
    pageData: CmsPageData;
    setPageData: (page: CmsPageData) => void;
    setFormHasChangesAction: () => void;

    imagesIndex: DocumentIndex;
    setImagesIndex: DocumentIndexSetter;
}) {
    const handleTextChange = useCallback(
        (event: any) => {
            const newData = {
                ...pageData.data,
                [event.target.name]: event.target.value,
            };
            setPageData({ ...pageData, data: newData });
            setFormHasChangesAction();
        },
        [pageData]
    );

    const handleCheckboxChange = useCallback(
        (event: any) => {
            const newData = {
                ...pageData.data,
                [event.target.name]: event.target.checked,
            };
            setPageData({ ...pageData, data: newData });
            setFormHasChangesAction();
        },
        [pageData]
    );

    const changeProject = useCallback(
        (event: React.ChangeEvent<{ value: unknown }>) => {
            const newData = {
                ...pageData.data,
                project: { id: event.target.value as number },
            };

            setPageData({ ...pageData, data: newData });
            setFormHasChangesAction();
        },
        [pageData]
    );

    const handlePropertiesTextChange = useCallback(
        (event: any) => {
            const newPageProperties = {
                ...pageData.pageProperties,
                [event.target.name]: event.target.value,
            };
            setPageData({ ...pageData, pageProperties: newPageProperties });
            setFormHasChangesAction();
        },
        [pageData]
    );

    const handlePropertiesImageChange = (newDocument: IDocument, name: string) => {
        const attrName = name as keyof CmsPageProperties;

        const imgObj = pageData?.pageProperties[attrName] as IDocumentRef | undefined;

        if (imgObj?.key == undefined) {
            setPageData({
                ...pageData,
                pageProperties: {
                    ...pageData.pageProperties,
                    [attrName]: { key: newDocument.key, type: DocumentTypeEnum.IMAGE },
                },
            });
        }

        setImagesIndex({
            ...imagesIndex,
            [newDocument.key as string]: newDocument,
        });

        setFormHasChangesAction();
    };

    return {
        handlePropertiesTextChange,
        handlePropertiesImageChange,
        handleTextChange,
        handleCheckboxChange,
        changeProject,
    };
}
