/* eslint-disable no-console */
import "./Comments.css";

import { Box, CircularProgress, Paper, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Pagination from "@material-ui/lab/Pagination";
import { AxiosResponse } from "axios";
import moment from "moment";
import React from "react";
import Masonry from "react-masonry-css";

import { COMMENT_PAGINATION, Format } from "../../config/config";
import IUser from "../../store/userStore/interfaces/IUser";
import IMandant from "../../types/IMandant";
import InitialComment from "../../types/initialData/InitialComment";
import InitialSnackbarState from "../../types/initialData/InitialSnackbarState";
import IPortalComment from "../../types/IPortalComment";
import IPortalCommentList from "../../types/IPortalCommentList";
import ISnackbarState from "../../types/ISnackbarState";
import IProjectFull from "../../types/project/IProjectFull";
import deepCopy from "../../utils/deepCopy";
import DevLogger from "../../utils/logger/DevLogger";
import { createCommentRequest, loadEventCommentsRequest } from "../../xhr/CommentRequests";
import HtmlContent from "../cms/renderer/content/components/HtmlContent";

export interface CommentsProps {
    mandant: IMandant;
    eventId: number;
    project: IProjectFull;
    user: IUser | null; // is null when the user is not logged in
}

const TaskComments = (props: CommentsProps) => {
    const { mandant, eventId, user, project } = props;

    const [commentList, setCommentList] = React.useState<IPortalCommentList[]>([]);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [commentsToDisplay, setCommentsToDisplay] = React.useState<IPortalCommentList[]>([]);

    const [commentData, setCommentData] = React.useState<IPortalComment>({
        ...InitialComment,
    });

    const [snackBar, setSnackBar] = React.useState<ISnackbarState>({
        ...InitialSnackbarState,
    });

    const [page, setPage] = React.useState(1);

    const breakpointColumnsObj = {
        default: 2,
        700: 1,
    };

    /**
     * here we load either the comments for an event or for the project
     */
    React.useEffect(() => {
        if (eventId) {
            setCommentData({
                ...commentData,
                event: { id: eventId },
            });
            loadEventCommentsRequest("" + mandant.key, eventId)
                .then((response: AxiosResponse<IPortalCommentList[]>) => {
                    setCommentList(response.data);

                    setCommentsToDisplay(
                        response.data.slice((page - 1) * COMMENT_PAGINATION, page * COMMENT_PAGINATION)
                    );
                })
                .catch((error: any) => {
                    DevLogger.logError(error);
                });
        }
    }, []);

    /**
     * The handleTextChange
     */
    const handleTextChange = (event: any) => {
        const newData = {
            ...commentData,
            [event.target.name]: event.target.value,
        };
        setCommentData(newData);
    };

    const closeSnackbar = () => {
        setSnackBar({ ...InitialSnackbarState });
    };

    const commentSaveClickAction = () => {
        setLoading(true);

        createCommentRequest("" + mandant.key, commentData)
            .then((response: AxiosResponse<IPortalComment>) => {
                //@ts-ignore
                commentsToDisplay.unshift(response.data);

                if (commentsToDisplay.length + 2 > COMMENT_PAGINATION) {
                    commentsToDisplay.pop();
                }

                setCommentsToDisplay([...commentsToDisplay]);
                //@ts-ignore
                commentList.unshift(response.data);
                setCommentList([...commentList]);

                setCommentData(
                    deepCopy<IPortalComment>({
                        ...InitialComment,
                        event: { id: eventId },
                    })
                );

                setSnackBar({
                    isOpen: true,
                    type: "success",
                    message: "Vielen Dank für Ihren Kommentar.",
                });

                setLoading(false);
            })
            .catch(() => {
                setSnackBar({
                    isOpen: true,
                    type: "error",
                    message: "Beim Erstellen des Kommentars ist ein Fehler aufgetreten.",
                });

                setLoading(false);
            });

        //
    };

    const handlePagerChange = (event: object, pagerPos: number) => {
        setPage(pagerPos);

        setCommentsToDisplay(commentList.slice((pagerPos - 1) * COMMENT_PAGINATION, pagerPos * COMMENT_PAGINATION));
    };

    return (
        <div className="ui comments">
            {mandant.key !== "silbernes-erzgebirge" && (
                <Typography className="project-comments-title" gutterBottom variant="h3">
                    {" "}
                    Was ist Ihnen sonst noch wichtig?
                </Typography>
            )}

            <HtmlContent content={project.commmentRulesText} className="gap-bottom gap-top" />

            <div className="gap-bottom">
                <TextField
                    className="comment-textarea"
                    fullWidth={true}
                    multiline
                    rows={5}
                    name="content"
                    disabled={!user}
                    onChange={handleTextChange}
                    value={commentData.content}
                    placeholder="Kommentar"
                    variant="outlined"
                    size="small"
                />
                {loading && <CircularProgress />}

                {!loading && (
                    <Button onClick={commentSaveClickAction} disabled={!user} color="primary">
                        Kommentieren
                    </Button>
                )}
            </div>

            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="masonry-grid comments-container gap-bottom"
                columnClassName="masonry-grid_column"
            >
                {commentsToDisplay.map((comment: IPortalCommentList, index: number) => {
                    return (
                        <div key={"comment" + index + comment.id}>
                            <Box>
                                <Paper elevation={0} className="comment_paper">
                                    <div className="comment" key={"comment_number_" + comment.id}>
                                        <div className="content">
                                            <div className="content-header">
                                                <div className="author">
                                                    {comment.user?.nickName
                                                        ? comment.user.nickName
                                                        : comment.nameOfPerson}
                                                </div>
                                                <div className="metadata">
                                                    <div>{moment(comment.creationDate).format(Format.date)}</div>
                                                </div>
                                            </div>
                                            <Typography variant="subtitle1">{comment.content}</Typography>
                                        </div>
                                    </div>
                                </Paper>
                            </Box>
                        </div>
                    );
                })}
            </Masonry>

            {commentList.length > COMMENT_PAGINATION && (
                <Pagination
                    className="comment-pager"
                    onChange={handlePagerChange}
                    count={Math.ceil(commentList.length / COMMENT_PAGINATION)}
                />
            )}

            <Snackbar open={snackBar.isOpen} autoHideDuration={6000} onClose={closeSnackbar}>
                <MuiAlert elevation={0} variant="filled" onClose={closeSnackbar} severity={snackBar.type}>
                    {snackBar.message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default TaskComments;
