import "./ProjectMenuTab.css";

import {
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    Paper,
    Select,
    TextField,
    Toolbar,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";
import { useConfirm } from "material-ui-confirm";
import React, { ReactNode, useEffect, useState } from "react";

import { ICmsPageChild } from "../../../../types/ICmsPage";
import IId from "../../../../types/IId";
import IMandant from "../../../../types/IMandant";
import ISnackbarState from "../../../../types/ISnackbarState";
import IProjectFull from "../../../../types/project/IProjectFull";
import deepCopy from "../../../../utils/deepCopy";
import ISelectOption from "../../../../xhr/interface/ISelectOption";
import {
    createProjectMenuRequest,
    loadProjectMenuRequest,
    updateProjectMenuRequest,
} from "../../../../xhr/ProjectRequests";
import defaultTabs from "../data/defaultTabs";

export interface IProjectMenu {
    data: {
        id: string;
        project: IId;
    };
    content: IMenuEntry[];
}

export interface IMenuEntry {
    activeIndex: number;
    label: string;
    url: string;
    type: "page" | "tab"; // 1
    target: string;
    children: IMenuEntry[];
    parentIndex?: number | string;
    oldParentIndex?: number | string;
}

const InitialMenuEntry: IMenuEntry = {
    activeIndex: 0,
    label: "",
    url: "",
    target: "",
    type: "tab",
    parentIndex: "",
    oldParentIndex: "",
    children: [],
};

interface IProjectCustomMenuTabProps {
    projectData: IProjectFull;
    mandant: IMandant;
    cmsPageProjectData: ICmsPageChild[];
    onTextChange: (event: any) => void;
    setSnackBarState: (state: ISnackbarState) => void;
}

const ProjectMenuTab = (props: IProjectCustomMenuTabProps) => {
    const confirm = useConfirm();
    const projectData = props.projectData;
    const { cmsPageProjectData } = props;
    const [menuData, setMenuData] = useState<IProjectMenu["data"]>();

    const [menuEntries, setMenuEntries] = useState<IMenuEntry[]>([]);
    const [activeEntry, setActiveEntry] = useState<IMenuEntry>({
        ...InitialMenuEntry,
    });
    const [isNew, setIsNew] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        loadProjectMenuRequest(props.mandant.key, "" + props.projectData.id).then((result: any) => {
            if (result.data?.data) {
                setMenuData(result.data.data);
                setMenuEntries(result.data.content);
            }
            setIsLoading(false);
        });
    }, [props.projectData.id, props.mandant.key]);

    const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setActiveEntry({
            ...activeEntry,
            [event.target.name]: event.target.value,
        });
    };

    const changeSelectbox = (event: React.ChangeEvent<any>) => {
        setActiveEntry({
            ...activeEntry,
            type: event.currentTarget.dataset.type,
            [event.target.name]: event.target.value,
        });
    };

    const changeParentSelectbox = (event: React.ChangeEvent<any>) => {
        if (activeEntry.oldParentIndex === "") {
            activeEntry.oldParentIndex = event.target.value;
        }
        activeEntry.parentIndex = event.target.value;

        setActiveEntry({
            ...activeEntry,
        });
    };

    const changeStartPageSelectbox = (event: React.ChangeEvent<any>) => {
        props.onTextChange(event);
    };

    const reCalcTheMenuIndices = (root: IMenuEntry[]): IMenuEntry[] => {
        root.forEach((menuEntry, index: number) => {
            menuEntry.activeIndex = index;
            menuEntry.parentIndex = "";

            if (menuEntry.children.length > 0) {
                menuEntry.children.forEach((subMenuEntry, subIndex: number) => {
                    subMenuEntry.parentIndex = index;
                    subMenuEntry.activeIndex = subIndex;
                });
            }
        });

        return root;
    };

    const onSaveMenuEntryToState = () => {
        if (activeEntry.parentIndex === "") {
            if (activeEntry.oldParentIndex !== "") {
                // @ts-ignore
                menuEntries[activeEntry.oldParentIndex].children.splice(activeEntry.activeIndex, 1);
                activeEntry.oldParentIndex = "";
            }

            if (isNew) {
                activeEntry.activeIndex = menuEntries.length;
                menuEntries.push(activeEntry);
            } else {
                menuEntries[activeEntry.activeIndex] = activeEntry;
            }
        } else {
            let parentMenuList;
            let parentIndex;

            // check if the parent is a sub menu
            if (activeEntry.parentIndex?.toString().includes(".")) {
                const rootIndex = parseInt(activeEntry.parentIndex?.toString().split(".")[0]);
                parentIndex = parseInt(activeEntry.parentIndex?.toString().split(".")[1]);

                console.log("index", { rootIndex, parentIndex, activeEntry });

                if (menuEntries[rootIndex].children[parentIndex].children === undefined) {
                    menuEntries[rootIndex].children[parentIndex].children = [];
                }

                parentMenuList = menuEntries[rootIndex].children[parentIndex].children;
            } else {
                parentIndex = parseInt(activeEntry.parentIndex?.toString() || "");
                parentMenuList = menuEntries[parentIndex].children;
            }

            if (isNew) {
                // @ts-ignore
                parentMenuList.push(activeEntry);
            } else {
                let append = false;

                if (activeEntry.oldParentIndex !== "") {
                    // @ts-ignore
                    parentMenuList.splice(activeEntry.activeIndex, 1);
                    activeEntry.oldParentIndex = "";
                    append = true;
                }

                if (activeEntry.parentIndex === "") {
                    if (append) {
                        activeEntry.activeIndex = menuEntries.length;
                        menuEntries.push(activeEntry);
                    } else {
                        menuEntries[activeEntry.activeIndex] = activeEntry;
                    }
                } else {
                    if (append) {
                        activeEntry.activeIndex = parentMenuList.length;
                        parentMenuList.push(activeEntry);
                    } else {
                        parentMenuList[activeEntry.activeIndex] = activeEntry;
                    }
                }
            }
        }

        setActiveEntry({
            ...InitialMenuEntry,
        });
        setIsNew(true);
        setMenuEntries(deepCopy<IMenuEntry[]>(menuEntries));
    };

    const onSaveMenuToServer = () => {
        setIsSaving(true);

        if (menuData?.id) {
            updateProjectMenuRequest(props.mandant.key, {
                data: menuData,
                content: menuEntries,
            }).then((result: any) => {
                // can be empty
                setMenuData(result.data.data);
                setMenuEntries(result.data.content);
                setIsSaving(false);

                props.setSnackBarState({
                    isOpen: true,
                    message: "Die Sortierung wurde erfolgreich gespeichert.",
                    type: "success",
                });
            });
        } else {
            createProjectMenuRequest(props.mandant.key, {
                data: {
                    id: "",
                    project: {
                        id: "" + props.projectData.id,
                    },
                },
                content: menuEntries,
            }).then((result: any) => {
                // can be empty
                setMenuData(result.data.data);
                setMenuEntries(result.data.content);
                setIsSaving(false);
            });
        }
    };

    const onDeleteMenuEntry = (index: number, delEntry: IMenuEntry, subMenuIndex?: number) => {
        confirm({
            description: "Möchten sie diese Seite wirklich löschen?",
            confirmationText: "Ja",
            cancellationText: "Nein",
            title: "Bitte bestätigen",
        }).then(() => {
            if (subMenuIndex !== undefined) {
                // @ts-ignore
                menuEntries[delEntry.parentIndex].children[subMenuIndex].children.splice(index, 1);
            } else {
                if (delEntry.parentIndex !== undefined && delEntry.parentIndex !== "") {
                    // @ts-ignore
                    menuEntries[delEntry.parentIndex].children.splice(index, 1);
                } else {
                    menuEntries.splice(index, 1);
                }
            }

            setMenuEntries(deepCopy<IMenuEntry[]>(reCalcTheMenuIndices(menuEntries)));
        });
    };

    const onEditMenuEntry = (index: number, subIndex?: number, subSubIndex?: number) => {
        setIsNew(false);

        if (subIndex != undefined && subSubIndex !== undefined) {
            setActiveEntry(deepCopy(menuEntries[index].children[subIndex].children[subSubIndex]));
        } else if (subIndex != undefined) {
            setActiveEntry(deepCopy(menuEntries[index].children[subIndex]));
        } else {
            setActiveEntry(deepCopy(menuEntries[index]));
        }
    };

    const moveSubMenuEntryUp = (index: number, subIndex: number, subSubIndex?: number) => {
        if (subSubIndex != undefined) {
            const varReplace = menuEntries[index].children[subIndex].children[subSubIndex - 1];

            menuEntries[index].children[subIndex].children[subSubIndex - 1] =
                menuEntries[index].children[subIndex].children[subSubIndex];
            menuEntries[index].children[subIndex].children[subSubIndex] = varReplace;
        } else {
            const varReplace = menuEntries[index].children[subIndex - 1];

            menuEntries[index].children[subIndex - 1] = menuEntries[index].children[subIndex];
            menuEntries[index].children[subIndex] = varReplace;
        }

        setMenuEntries(deepCopy(reCalcTheMenuIndices(menuEntries)));
    };

    const moveSubMenuEntryDown = (index: number, subIndex: number, subSubIndex?: number) => {
        if (subSubIndex != undefined) {
            const varReplace = menuEntries[index].children[subIndex].children[subSubIndex + 1];
            menuEntries[index].children[subIndex].children[subSubIndex + 1] =
                menuEntries[index].children[subIndex].children[subSubIndex];
            menuEntries[index].children[subIndex].children[subSubIndex] = varReplace;
        } else {
            const varReplace = menuEntries[index].children[subIndex + 1];
            menuEntries[index].children[subIndex + 1] = menuEntries[index].children[subIndex];
            menuEntries[index].children[subIndex] = varReplace;
        }

        setMenuEntries(deepCopy(reCalcTheMenuIndices(menuEntries)));
    };

    const moveMenuEntryUp = (index: number) => {
        const varReplace = menuEntries[index - 1];

        menuEntries[index - 1] = menuEntries[index];
        menuEntries[index] = varReplace;

        setMenuEntries(deepCopy(reCalcTheMenuIndices(menuEntries)));
    };

    const moveMenuEntryDown = (index: number) => {
        const varReplace = menuEntries[index + 1];

        menuEntries[index + 1] = menuEntries[index];
        menuEntries[index] = varReplace;

        setMenuEntries(deepCopy(reCalcTheMenuIndices(menuEntries)));
    };

    if (isLoading) {
        return (
            <Paper variant="outlined" className="main-paper">
                <Typography variant="h2" className="main-header gap-bottom">
                    Projekt Menu
                </Typography>
                <LinearProgress />
            </Paper>
        );
    }

    console.log("menuEntries", menuEntries);

    return (
        <Paper variant="outlined" className="main-paper">
            <Grid container spacing={2} direction="row">
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth={true} required={true}>
                        <InputLabel id="menu-select-project-label">Startseite</InputLabel>
                        <Select
                            labelId="project-data-entry-page"
                            value={projectData.entryPage}
                            onChange={changeStartPageSelectbox}
                            name="entryPage"
                            label="Startseite (Wenn abweichend)"
                        >
                            <MenuItem key="empty" value="">
                                <em>Bitte Ziel wählen</em>
                            </MenuItem>
                            <MenuItem key="Header-tabs" value="" disabled={true}>
                                <strong>Standard Tabs</strong>
                            </MenuItem>
                            {defaultTabs.map((defTab: ISelectOption, index: number) => {
                                return (
                                    <MenuItem key={"tab-" + index} value={defTab.id} data-type="tab">
                                        {" "}
                                        Tab: {defTab.label}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem key="Header-pages" value="" disabled={true}>
                                <strong>Seiten</strong>
                            </MenuItem>
                            {cmsPageProjectData.map((page: ICmsPageChild, index: number) => {
                                return (
                                    <MenuItem key={"page-" + index} value={page.urlKey} data-type="page">
                                        Seite: {page.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <Alert severity="info" className="gap-top gap-bottom">
                        Wenn die Standardstartseite überschrieben werden soll bitte hier die neue Startseite wählen.
                    </Alert>
                </Grid>
            </Grid>

            <Typography variant="h2" className="main-header gap-bottom">
                Projekt Menu
            </Typography>

            <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                    <Toolbar disableGutters={true}>
                        <Button disabled={isSaving} onClick={onSaveMenuToServer} color="primary">
                            Menu Speichern
                        </Button>
                        <Button disabled={isSaving} color="primary">
                            Sortieren aktivieren
                        </Button>
                    </Toolbar>
                    {isSaving && <LinearProgress />}
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        name="label"
                        label="Label"
                        fullWidth
                        value={activeEntry.label}
                        onChange={handleTextChange}
                        variant="outlined"
                        required={true}
                    />

                    <FormControl variant="outlined" fullWidth={true} required={true}>
                        <InputLabel id="menu-select-project-label">Tab / Seite</InputLabel>
                        <Select
                            labelId="menu-select-project-label"
                            value={activeEntry.url}
                            required={true}
                            onChange={changeSelectbox}
                            name="url"
                            label="Tab / Seite *"
                        >
                            <MenuItem key="empty" value="">
                                <em>Bitte Ziel wählen</em>
                            </MenuItem>
                            <MenuItem key="Header-tabs" value="" disabled={true}>
                                <strong>Standard Tabs</strong>
                            </MenuItem>
                            {defaultTabs.map((defTab: ISelectOption, index: number) => {
                                return (
                                    <MenuItem key={"tab-" + index} value={defTab.id} data-type="tab">
                                        {" "}
                                        Tab: {defTab.label}
                                    </MenuItem>
                                );
                            })}
                            <MenuItem key="Header-pages" value="" disabled={true}>
                                <strong>Seiten</strong>
                            </MenuItem>
                            {cmsPageProjectData.map((page: ICmsPageChild, index: number) => {
                                return (
                                    <MenuItem key={"page-" + index} value={page.urlKey} data-type="page">
                                        Seite: {page.title}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <FormControl variant="outlined" fullWidth={true}>
                        <InputLabel id="menu-select-parent">Parent</InputLabel>
                        <Select
                            labelId="project-select-label"
                            onChange={changeParentSelectbox}
                            value={"" + activeEntry.parentIndex}
                            name="parent"
                            label="Parent"
                        >
                            <MenuItem key="empty" value="">
                                <em>Bitte Obermenü wählen</em>
                            </MenuItem>
                            {menuEntries.map((entry: IMenuEntry, index: number) => {
                                const entries = [
                                    <MenuItem key={"parent-" + index} value={index}>
                                        {entry.label}
                                    </MenuItem>,
                                ];

                                entry.children.map((subEntry: IMenuEntry, subIndex: number) => {
                                    entries.push(
                                        <MenuItem
                                            key={"sub-parent-" + index + "-" + subIndex}
                                            value={index + "." + subIndex}
                                            style={{ paddingLeft: "30px" }}
                                        >
                                            &gt; {subEntry.label}
                                        </MenuItem>
                                    );
                                });

                                return entries;
                            })}
                        </Select>
                    </FormControl>

                    <Button color="primary" onClick={onSaveMenuEntryToState}>
                        {isNew ? "Eintrag hinzufügen" : "Eintrag editieren"}
                    </Button>
                </Grid>

                <Grid item xs={6}>
                    <List component="nav" aria-labelledby="nested-list-subheader" className="menu-editor">
                        {menuEntries.map((menuEntry, index: number) => {
                            let label = menuEntry.type === "page" ? "Seite: " : "Tab :";
                            label += " " + menuEntry.label + " -> " + menuEntry.url;

                            const dannHaltNenArray = [
                                <ListItem button key={"" + index}>
                                    <ListItemText primary={label} />
                                    {index > 0 && (
                                        <ListItemIcon
                                            onClick={() => {
                                                moveMenuEntryUp(index);
                                            }}
                                        >
                                            <ArrowUpwardIcon />
                                        </ListItemIcon>
                                    )}
                                    {menuEntries.length - 1 !== index ? (
                                        <ListItemIcon
                                            onClick={() => {
                                                moveMenuEntryDown(index);
                                            }}
                                        >
                                            <ArrowDownwardIcon />
                                        </ListItemIcon>
                                    ) : (
                                        <ListItemIcon> </ListItemIcon>
                                    )}
                                    <ListItemIcon
                                        onClick={() => {
                                            onEditMenuEntry(index);
                                        }}
                                    >
                                        <EditIcon />
                                    </ListItemIcon>
                                    <ListItemIcon
                                        onClick={() => {
                                            onDeleteMenuEntry(index, menuEntry);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </ListItemIcon>
                                </ListItem>,
                            ];

                            if (menuEntry.children.length > 0) {
                                const subMenuNode = (
                                    <List
                                        component="div"
                                        className="menu-editor-submenu"
                                        key={index + "" + menuEntry.activeIndex}
                                    >
                                        {menuEntry.children.map((subMenuEntry, subIndex: number) => {
                                            let subLabel = subMenuEntry.type === "page" ? "Seite: " : "Tab :";
                                            subLabel += " " + subMenuEntry.label + " -> " + subMenuEntry.url;

                                            const subSubMenuNodes: JSX.Element[] = [];

                                            if (
                                                subMenuEntry.children != undefined &&
                                                subMenuEntry.children.length > 0
                                            ) {
                                                subMenuEntry.children.forEach((subSubEntry, subSubIndex) => {
                                                    let subSubLabel = subSubEntry.type === "page" ? "Seite: " : "Tab :";
                                                    subSubLabel += " " + subSubEntry.label + " -> " + subLabel;

                                                    subSubMenuNodes.push(
                                                        <ListItem
                                                            button
                                                            key={index + "-" + subIndex + "-" + subSubIndex}
                                                        >
                                                            <ListItemText primary={subSubLabel} />
                                                            {subSubIndex > 0 && (
                                                                <ListItemIcon
                                                                    onClick={() => {
                                                                        moveSubMenuEntryUp(
                                                                            index,
                                                                            subIndex,
                                                                            subSubIndex
                                                                        );
                                                                    }}
                                                                >
                                                                    <ArrowDropUpIcon />
                                                                </ListItemIcon>
                                                            )}
                                                            {subSubEntry.children.length - 1 !== subSubIndex ? (
                                                                <ListItemIcon
                                                                    onClick={() => {
                                                                        moveSubMenuEntryDown(
                                                                            index,
                                                                            subIndex,
                                                                            subSubIndex
                                                                        );
                                                                    }}
                                                                >
                                                                    <ArrowDropDownIcon />
                                                                </ListItemIcon>
                                                            ) : (
                                                                <ListItemIcon> </ListItemIcon>
                                                            )}
                                                            <ListItemIcon
                                                                onClick={() => {
                                                                    onEditMenuEntry(index, subIndex, subSubIndex);
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </ListItemIcon>
                                                            <ListItemIcon
                                                                onClick={() => {
                                                                    onDeleteMenuEntry(
                                                                        subSubIndex,
                                                                        subSubEntry,
                                                                        subIndex
                                                                    );
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </ListItemIcon>
                                                        </ListItem>
                                                    );
                                                });
                                            }

                                            const theEntries = [
                                                <ListItem button key={index + "-" + subIndex}>
                                                    <ListItemText primary={subLabel} />
                                                    {subIndex > 0 && (
                                                        <ListItemIcon
                                                            onClick={() => {
                                                                moveSubMenuEntryUp(index, subIndex);
                                                            }}
                                                        >
                                                            <ArrowDropUpIcon />
                                                        </ListItemIcon>
                                                    )}
                                                    {menuEntry.children.length - 1 !== subIndex ? (
                                                        <ListItemIcon
                                                            onClick={() => {
                                                                moveSubMenuEntryDown(index, subIndex);
                                                            }}
                                                        >
                                                            <ArrowDropDownIcon />
                                                        </ListItemIcon>
                                                    ) : (
                                                        <ListItemIcon> </ListItemIcon>
                                                    )}
                                                    <ListItemIcon
                                                        onClick={() => {
                                                            onEditMenuEntry(index, subIndex);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </ListItemIcon>
                                                    <ListItemIcon
                                                        onClick={() => {
                                                            onDeleteMenuEntry(subIndex, subMenuEntry);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </ListItemIcon>
                                                </ListItem>,
                                            ];

                                            if (subSubMenuNodes.length > 0) {
                                                theEntries.push(
                                                    <List
                                                        component="div"
                                                        className="menu-editor-sub-sub-menu"
                                                        key={index + "" + menuEntry.activeIndex + "sub"}
                                                    >
                                                        {subSubMenuNodes}
                                                    </List>
                                                );
                                            }

                                            return theEntries;
                                        })}
                                    </List>
                                );

                                dannHaltNenArray.push(subMenuNode);
                            }
                            dannHaltNenArray.push(<Divider key={"divider-" + index} />);

                            return dannHaltNenArray;
                        })}
                    </List>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default ProjectMenuTab;
