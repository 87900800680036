import React from "react";

import CmsPageRenderer from "../../../../components/cms/renderer/CmsPageRenderer";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/project/IProjectFull";

interface IProjectDataContentProps {
    mandant: IMandant;
    pageKey?: string;
    isMobileSize: boolean;
    projectData: IProjectFull;
    activeContent: React.ReactElement;
}

const ProjectDataContent = (props: IProjectDataContentProps) => {
    const { pageKey, mandant, projectData, activeContent } = props;
    if (pageKey) {
        return <CmsPageRenderer mandant={mandant} projectKey={projectData.urlKey} pageKey={pageKey} />;
    }
    return activeContent;
};

export default ProjectDataContent;
