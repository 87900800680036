import { Button, Grid, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconAdd from "@material-ui/icons/Add";
import React from "react";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import EventTile from "../../../../components/project/eventTile/EventTile";
import { TitleContainerSelectbox } from "../../../../components/titleContainerSelectbox/TitleContainerSelectbox";
import IDocument from "../../../../types/IDocument";
import IEventList from "../../../../types/IEventList";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/project/IProjectFull";

interface IOwnProps {
    mandant: IMandant;
    onTextChange: (event: any) => void;
    handleTextCustomFieldsChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    projectData: IProjectFull;
    events: IEventList[];
    onEditClick: (event: any) => void;
    onPublishClick: (event: any) => void;
    onDeleteClick: (event: any) => void;
    onCreateProjectClick: (event: any) => void;
}

interface IEventContainerProps extends IOwnProps {}

const EventListingTab = (props: IEventContainerProps) => {
    const { projectData, onTextChange, onImageChange, handleTextCustomFieldsChange } = props;

    return (
        <Paper variant="outlined" className={"main-paper"}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Veranstaltungen Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden, werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name="menuTabEvents"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabEvents}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TextField
                        name="titleTabEvents"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabEvents}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TitleContainerSelectbox
                        selectedValue={projectData.customFields?.headerBgTabInfo || ""}
                        name={"headerBgTabInfo"}
                        setSelectedValue={handleTextCustomFieldsChange}
                    />

                    <TextField
                        name="descTabEvents"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabEvents}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabEvents}
                        idKey={"headerTabEvents"}
                        name={"headerTabEvents"}
                        label={"Header Bild"}
                        defaultFilename="Header.jpg"
                        minSourceImageWidth={2000}
                        minSourceImageHeight={1125}
                        imageExportHeightInPixels={1125}
                        imageExportWidthInPixels={2000}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Veranstaltungen
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <Button onClick={props.onCreateProjectClick} color="primary" startIcon={<IconAdd />}>
                        Eine neue Veranstaltung anlegen
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {props.events
                    .sort((a: any, b: any) => {
                        const firstdate = new Date(a.theStart);
                        const seconddate = new Date(b.theStart);
                        return firstdate.getTime() - seconddate.getTime();
                    })
                    .map((event: IEventList, index) => (
                        <Grid item xs={12} sm={6} md={3} lg={4} key={`event-${index}`}>
                            <EventTile
                                {...event}
                                onLinkClick={props.onEditClick}
                                onPublishClick={() => props.onPublishClick(event)}
                                onDeletelick={() => props.onDeleteClick(event)}
                                editLabel="Bearbeiten"
                            />
                        </Grid>
                    ))}
            </Grid>
        </Paper>
    );
};

export default EventListingTab;
