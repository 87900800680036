import IProjectResult from "../project/IProjectResult";
import InitialDocumentData from "./InitialDocumentData";

const InitialProjectResultElementData: IProjectResult = {
    id: null,
    title: "",
    description: "",
    linkTitel: "",
    priority: 0,
    previewImage: { ...InitialDocumentData },
    document: { ...InitialDocumentData },
    project: "",
};

export default InitialProjectResultElementData;
