import "./ProjectTitle.css";

import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import IconDelete from "@material-ui/icons/Delete";
import IconEdit from "@material-ui/icons/Edit";
import IconPublish from "@material-ui/icons/Visibility";
import IconUnPublish from "@material-ui/icons/VisibilityOff";
import moment from "moment";
import React from "react";

import { API_URL_STATIC, Format } from "../../../config/config";
import IProjectList from "../../../types/project/IProjectList";

interface IProjectTileParams extends IProjectList {
    onLinkClick?: (event: any) => void;
    onEditClick?: (event: any) => void;
    onDeleteClick?: (event: any) => void;
    onPublishClick?: (event: any) => void;
    editLabel?: string;
}

const useStyles = makeStyles({
    root: {
        width: "100%",
        marginRight: "20px",
        marginBottom: "20px",
        height: "100%",
    },
    media: {
        height: 160,
    },
});

const ProjectTile = (props: IProjectTileParams) => {
    const classes = useStyles();

    return (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <Card
                variant="outlined"
                square
                className={classes.root}
                data-id={props.urlKey}
                onClick={props.onLinkClick ? props.onLinkClick : () => null}
                style={{ height: "100%" }}
            >
                <CardActionArea>
                    <CardMedia
                        className={`${classes.media} card-image-container`}
                        image={API_URL_STATIC + props.imageMenu?.url}
                        title={props.imageMenu?.description}
                    />
                    {props.flagArchive && (
                        <div className="archived-icon-container">
                            <ArchiveOutlinedIcon className="archived-icon-container__archived-icon" />
                        </div>
                    )}
                    <CardContent>
                        <Typography gutterBottom variant="h3" component="h3">
                            {props.title}
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="h3">
                            {moment(props.theStart).format(Format.date)}
                            {props.theEnd && " bis " + moment(props.theEnd).format(Format.date)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" align="left">
                            {props.shortDesc}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {props.onLinkClick ? (
                        <Button data-id={props.urlKey} size="small" color="primary" onClick={props.onLinkClick}>
                            Mehr lesen...
                        </Button>
                    ) : (
                        <></>
                    )}
                    {props.onEditClick && props.accessLevel >= 1 ? (
                        <IconButton data-id={props.id} onClick={props.onEditClick} title={"Editieren"}>
                            <IconEdit />
                        </IconButton>
                    ) : (
                        <></>
                    )}
                    {props.onPublishClick && props.accessLevel >= 3 ? (
                        <IconButton
                            data-id={props.id}
                            onClick={props.onPublishClick}
                            title={props.flagPublished ? "Depublizieren" : "Publizieren"}
                        >
                            {props.flagPublished ? <IconUnPublish /> : <IconPublish />}
                        </IconButton>
                    ) : (
                        <></>
                    )}
                    {props.onDeleteClick && props.accessLevel >= 3 ? (
                        <IconButton
                            data-id={props.id}
                            color="secondary"
                            onClick={props.onDeleteClick}
                            title={"Löschen"}
                        >
                            <IconDelete />
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </CardActions>
            </Card>
        </Grid>
    );
};

export default ProjectTile;
