import { FormControl, InputLabel, Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

import ISelectOption from "../../xhr/interface/ISelectOption";

export type TitleBackgroundVariants = "no_background" | "dark_bg" | "light_bg" | undefined;

const backgroundOptions: ISelectOption[] = [
    {
        id: "no_background",
        label: "Kein Hintergrund",
    },
    {
        id: "dark_bg",
        label: "Dunkler Hintergrund",
    },
    {
        id: "light_bg",
        label: "Light Hintergrund",
    },
];

export interface TitleContainerSelectboxProps {
    selectedValue: string;
    setSelectedValue: (event: any) => void;
    name: string;
}

export function TitleContainerSelectbox({ selectedValue, setSelectedValue, name }: TitleContainerSelectboxProps) {
    return (
        <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="layout-select-label">Hintergrund Titel</InputLabel>
            <Select
                labelId="layout-select-label"
                label="Layout"
                value={selectedValue}
                name={name}
                onChange={setSelectedValue}
            >
                <MenuItem key="empty" value="">
                    <em>Hintergrund wählen</em>
                </MenuItem>
                {backgroundOptions.map((layout: ISelectOption, index: number) => {
                    return (
                        <MenuItem key={"layout-" + index} value={layout.id}>
                            {layout.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}
