import "./eventData.css";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import download from "downloadjs";
import ical from "ical-generator";
import moment from "moment";
import React from "react";
import { Helmet } from "react-helmet";
import { MapStateToPropsParam, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import AccordionUi from "../../../components/accordion/Accordion";
import TabContainer, { ITab } from "../../../components/tabContainer/TabContainer";
import { Format, PAGE_BREAK_MOBILE } from "../../../config/config";
import useWindowSize from "../../../customhooks/useWindowSize";
import PortalFooter from "../../../layout/portalFooter/PortalFooter";
import PortalHeader from "../../../layout/portalHeader/PortalHeader";
import RoutesEnum from "../../../routes/RoutesEnum";
import IApplicationRootState from "../../../store/StoreTypes";
import IUser from "../../../store/userStore/interfaces/IUser";
import IEventFull from "../../../types/IEventFull";
import IMandant from "../../../types/IMandant";
import InitialEventFullData from "../../../types/initialData/InitialEventFullData";
import ITheme from "../../../types/ITheme";
import IProjectFull from "../../../types/project/IProjectFull";
import { toPortalUrl } from "../../../utils/toPortalUrl";
import { loadPublicEventData } from "../../../xhr/EventRequests";
import { ProjectTabEnum } from "../projectData/ProjectTabEnum";
import AboutTab from "./tabs/AboutTab";
import CommentTab from "./tabs/CommentTab";
import ReportTab from "./tabs/ReportTab";

interface IMapStateProps {
    mandantTheme: ITheme;
    actualUser: IUser;
}

interface IOwnProps {
    mandant: IMandant;
}

interface IEventDataProps extends IMapStateProps, IOwnProps {}

const EventData = (props: IEventDataProps) => {
    const WindowWidth = useWindowSize();
    const { id }: { id: string } = useParams();
    const [eventDataState, setEventData] = React.useState<IEventFull>({
        ...InitialEventFullData,
    });
    const { mandant, actualUser } = props;
    const history = useHistory();
    const [activeTab, setActiveTab] = React.useState(1);
    const [showBericht, setShowBericht] = React.useState(false);

    const getTabs = (show: boolean): ITab[] => {
        return [
            {
                id: "tab-event-report",
                label: "Veranstaltungsbericht",
                tabKey: 1,
                display: show,
                content: <ReportTab event={eventDataState} />,
            },
            {
                id: "tab-event-content",
                label: "Über die Veranstaltung",
                tabKey: 2,
                display: !show,
                content: <AboutTab event={eventDataState} />,
            },
            {
                id: "tab-comments",
                label: "Kommentare",
                tabKey: 3,
                display: false,
                content: <CommentTab event={eventDataState} user={actualUser} mandant={mandant} />,
            },
        ];
    };

    React.useEffect(() => {
        loadPublicEventData(mandant.key, id).then((result) => {
            setEventData(result.data);
            const show = !!result.data.descriptionAfter || !!result.data.imagesAfter.length;
            setShowBericht(show);

            const tabToDisplay = getTabs(show).find((tabData: ITab) => tabData.display);

            if (tabToDisplay !== undefined) {
                const firstLoadedTab = Number(tabToDisplay.tabKey);

                setActiveTab(firstLoadedTab);
            }
            return Promise.resolve();
        });
    }, [mandant.key, id]);

    const handleChange = (event: React.ChangeEvent<{}>, tabIdx: number) => {
        setActiveTab(tabIdx);
    };

    if (!eventDataState?.id) {
        return <></>;
    }

    const onClickProject = (event: React.SyntheticEvent<any>) => {
        history.push(toPortalUrl(mandant, RoutesEnum.PORTAL_PROJECT + "/" + event.currentTarget.dataset.id));
    };

    const project: IProjectFull = eventDataState.project as IProjectFull;
    const handleClickDownloadEvent = () => {
        const cal = ical({ name: eventDataState.title });
        cal.createEvent({
            start: eventDataState.theStart ? new Date(eventDataState.theStart) : "",
            end: eventDataState.theEnd ? new Date(eventDataState.theEnd) : "",
            summary: "" + eventDataState.shortDesc,
            description: "" + eventDataState.shortDesc,
            location: eventDataState.locationName + " " + eventDataState.locationUrl,
        });
        const dataTodownload = cal.toString();
        download(dataTodownload, eventDataState.title + ".ics", "text/calendar");
    };

    const fitStyle = () => {
        if (WindowWidth > PAGE_BREAK_MOBILE) {
            return {};
        } else {
            return { margin: "2px" };
        }
    };

    return (
        <div className="portal-section">
            <Helmet>
                <title>Veranstaltung: {eventDataState.title}</title>
            </Helmet>

            <PortalHeader mandant={mandant} activeTab={ProjectTabEnum.EVENTS_TAB} />

            <div className="portal-section-body-position">
                <div className={"App-main"} style={fitStyle()}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="App-data-container">
                                <Typography className="App-data-header" gutterBottom variant="h2">
                                    {eventDataState.title}
                                </Typography>
                                <Grid container>
                                    <Typography className="App-data-header2" gutterBottom variant="h3">
                                        ab: {moment(eventDataState.theStart).format(Format.dateTime)}
                                        {" Uhr "}
                                        bis:{" "}
                                        {moment(eventDataState.theEnd).format(
                                            /*  */
                                            "HH:mm"
                                        )}
                                        {" Uhr "}
                                    </Typography>
                                    <div title="Klicken Sie hier zum Herunterladen">
                                        <CalendarTodayIcon
                                            className="calendar-button"
                                            color="primary"
                                            onClick={handleClickDownloadEvent}
                                            fontSize="small"
                                        />
                                    </div>
                                </Grid>
                                <Typography
                                    className="App-data-header2 clickable"
                                    gutterBottom
                                    variant="h3"
                                    onClick={onClickProject}
                                    data-id={project.id}
                                >
                                    Zurück zum Projekt: {project.title}
                                </Typography>
                                {WindowWidth > PAGE_BREAK_MOBILE ? (
                                    <TabContainer
                                        tabs={getTabs(showBericht)}
                                        activeTab={activeTab}
                                        ariaLabel={"Public Event Data"}
                                        idKey={"event Data"}
                                        handleChange={handleChange}
                                    />
                                ) : (
                                    <AccordionUi tabs={getTabs(showBericht)} />
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <PortalFooter mandant={mandant} activeProjectKey={eventDataState?.project?.urlKey} />
        </div>
    );
};

/** Redux  */
const mapStateToProps: MapStateToPropsParam<IMapStateProps, IOwnProps, IApplicationRootState> = (
    state: IApplicationRootState
) => {
    return {
        mandantTheme: state.mandant.mandantTheme,
        actualUser: state.user.userData as IUser,
    };
};

export default connect(mapStateToProps)(EventData);
