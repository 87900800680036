import { ICmsPageData } from "../../../types/ICmsPage";
import IDocument from "../../../types/IDocument";
import IDocumentRef from "../../../types/IDocumentRef";
import ITeam from "../../../types/ITeam";
import ITeamRef from "../../../types/ITeamRef";
import ISelectOption from "../../../xhr/interface/ISelectOption";
import { KfwCalculationConfig } from "../../fundingCalculatorForm/logic/FundingCalculatorForm.types";
import { CmsCard } from "./CmsCard";
import CmsFaqQuestion from "./CmsFaqQuestion";

export const cmsPageLayoutTypes: Record<string, string> = {
    l_1: "Voll",
    l_1_1: "1/2 | 1/2",
    l_1_1_1: "1/3 | 1/3 | 1/3",
    l_2_1: "2/3 | 1/3",
    l_1_2: "1/3 | 2/3",
    l_1_2_1: "1/4 | 1/2 | 1/4",
    l_1_1_1_1: "1/4 | 1/4 | 1/4 | 1/4",
} as const;

export const cmsPageLayoutSelection = Object.keys(cmsPageLayoutTypes).map((key: string) => {
    return {
        id: key,
        label: cmsPageLayoutTypes[key] as string,
    } as ISelectOption;
});

export type CmsPageLayoutTypes = keyof typeof cmsPageLayoutTypes;

export const cmsContentTypes: Record<string, string> = {
    header_1: "H1 Header",
    header_2: "H2 Header",
    header_3: "H3 Header",
    header_4: "H4 Header",
    html: "Freitext HTML",
    text: "Text unformatiert",
    image: "Bild",
    carousel: "Bilder Carousel",
    attachment: "Download",
    attachment_list: "Download Liste",
    person: "Personen Kachel",
    person_list: "Personen Liste",
    //card: "Card",
    faq: "Faq Abschnitt",
    map_comments: "Pinnwand Karte",
    comments: "Pinnwand",
    calculator: "Förderrechner",
} as const;

export const cmsContentSelection = Object.keys(cmsContentTypes).map((key: string) => {
    return {
        id: key,
        label: cmsContentTypes[key] as string,
    } as ISelectOption;
});

export type CmsPageContentTypes = keyof typeof cmsContentTypes;

export type CmsPageDataSetter = (pageData: CmsPageData) => void;

export interface CmsPageStructure {
    elements: CmsSectionIndexBlock[];
}

export interface CmsPageData {
    data: ICmsPageData;
    pageProperties: CmsPageProperties;
    elements: CmsSectionBlock[];
}

export interface CmsSyncPageData {
    data: ICmsPageData;
    elements: {
        pageProperties: CmsPageProperties;
        elements: CmsSectionBlock[];
    };
    images: IDocument[];
    uploads: IDocument[];
    teams: ITeam[];
}

export interface CmsPageProperties {
    pageDescription?: string;
    headerImage?: IDocumentRef;
    titleContainerBackground?: "no_background" | "dark_bg" | "light_bg" | undefined;
}

export interface CmsSectionIndexBlock {
    id: string;
    layout: CmsPageLayoutTypes;
    content: string[];
}

export interface CmsSectionBlock {
    id: string;
    layout: CmsPageLayoutTypes;
    content: CmsContentBlock[];
}

export interface CmsContentBlockBase {
    id: string;
    type: CmsPageContentTypes;
}

export interface CmsContentBlockText extends CmsContentBlockBase {
    id: string;
    type: "header_1" | "header_2" | "header_3" | "header_4" | "html" | "text";
    data: string;
}

export interface CmsContentBlockPerson extends CmsContentBlockBase {
    id: string;
    type: "person";
    data: ITeamRef;
}

export interface CmsContentBlockPersonList extends CmsContentBlockBase {
    id: string;
    type: "person_list";
    data: ITeamRef[];
}

export interface CmsContentBlockImage extends CmsContentBlockBase {
    id: string;
    type: "image";
    data: IDocumentRef;
}

export interface CmsContentBlockCarousel extends CmsContentBlockBase {
    id: string;
    type: "carousel";
    data: IDocumentRef[];
}

export interface CmsContentBlockCard extends CmsContentBlockBase {
    id: string;
    type: "card";
    data: CmsCard;
}

export interface CmsContentBlockAttachment extends CmsContentBlockBase {
    id: string;
    type: "attachment";
    data: IDocumentRef;
}

export interface CmsContentBlockAttachmentList extends CmsContentBlockBase {
    id: string;
    type: "attachment_list";
    data: IDocumentRef[];
}

export interface CmsContentBlockFaq extends CmsContentBlockBase {
    id: string;
    type: "faq";
    data: CmsFaqQuestion[];
}

export interface CmsContentBlockCalculator extends CmsContentBlockBase {
    id: string;
    type: "calculator";
    data: KfwCalculationConfig | undefined;
}

export interface CmsContentBlockMapComments extends CmsContentBlockBase {
    id: string;
    type: "map_comments";
    data: string | undefined;
}

export interface CmsContentBlockComments extends CmsContentBlockBase {
    id: string;
    type: "comments";
    data: string | undefined;
}

export type CmsContentBlock =
    | CmsContentBlockText
    | CmsContentBlockPerson
    | CmsContentBlockPersonList
    | CmsContentBlockImage
    | CmsContentBlockCarousel
    | CmsContentBlockCard
    | CmsContentBlockFaq
    | CmsContentBlockCalculator
    | CmsContentBlockMapComments
    | CmsContentBlockComments
    | CmsContentBlockAttachment
    | CmsContentBlockAttachmentList;
