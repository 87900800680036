import React from "react";

import IUser from "../../../../store/userStore/interfaces/IUser";
import ICampaign from "../../../../types/appointment/ICampaign";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/project/IProjectFull";
import { ProjectTabEnum } from "../ProjectTabEnum";
import ProjectAboutTab from "../tabs/ProjectAboutTab";
import ProjectCampaignTab from "../tabs/ProjectCampaignTab";
import ProjectConsultingTab from "../tabs/ProjectConsultingTab";
import ProjectEventsTab from "../tabs/ProjectEventsTab";
import ProjectParticipateTab from "../tabs/ProjectParticipateTab";
import ProjectResultsTab from "../tabs/ProjectResultTab";
import ProjectStartTab from "../tabs/ProjectStartTab";
import ProjectTeamTab from "../tabs/ProjectTeamTab";

const getActiveProjectSection = (
    mandant: IMandant,
    projectData: IProjectFull,
    actualUser: IUser,
    campaignData: ICampaign | null,
    activeTab: ProjectTabEnum
): React.ReactElement => {
    switch (activeTab) {
        case ProjectTabEnum.START_TAB: {
            return <ProjectStartTab project={projectData} />;
        }
        case ProjectTabEnum.INFO_TAB: {
            return <ProjectAboutTab project={projectData} mandant={mandant} />;
        }
        case ProjectTabEnum.EVENTS_TAB: {
            return <ProjectEventsTab project={projectData} mandant={mandant} />;
        }
        case ProjectTabEnum.RESULTS_TAB: {
            return <ProjectResultsTab project={projectData} />;
        }
        case ProjectTabEnum.PARTICIPATE_TAB: {
            return <ProjectParticipateTab project={projectData} mandant={mandant} actualUser={actualUser} />;
        }
        case ProjectTabEnum.CONSULTING_TAB: {
            return <ProjectConsultingTab project={projectData} mandant={mandant} actualUser={actualUser} />;
        }
        case ProjectTabEnum.TEAM_TAB: {
            return <ProjectTeamTab project={projectData} />;
        }
        case ProjectTabEnum.CAMPAIGN_TAB: {
            return campaignData ? <ProjectCampaignTab mandant={mandant} campaign={campaignData} /> : <></>;
        }
        default: {
            return <ProjectStartTab project={projectData} />;
        }
    }
};

export default getActiveProjectSection;
