import { Button, CircularProgress, Grid, LinearProgress, Paper, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconAdd from "@material-ui/icons/Add";
import IconGames from "@material-ui/icons/Games";
import IconOpenWith from "@material-ui/icons/OpenWith";
import IconSave from "@material-ui/icons/Save";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import { TitleContainerSelectbox } from "../../../../components/titleContainerSelectbox/TitleContainerSelectbox";
import IDocument from "../../../../types/IDocument";
import IMandant from "../../../../types/IMandant";
import IProjectFull from "../../../../types/project/IProjectFull";
import IProjectResult from "../../../../types/project/IProjectResult";
import ProjectResultCard from "../components/cards/ProjectResultCard";
import { IProjectValidationStateWithDocumentAndImage } from "../projectDataValidation";

interface IProjectResultsTabProps {
    mandant: IMandant;
    projectResultsErrorState: IProjectValidationStateWithDocumentAndImage[];
    onTextChange: (event: any) => void;
    handleTextCustomFieldsChange: (event: any) => void;
    onImageChange: (image: IDocument, attrKey: string) => void;
    onCheckboxChange: (event: any) => void;
    projectData: IProjectFull;
    projectResults?: IProjectResult[];
    onUpload: (image: IProjectResult, index: number) => void;
    onDelete: (index: number) => void;
    onReset: (index: number, imageId: number) => void;
    onSave: () => void;
    onImageCreate: () => void;
    loadingGallery: boolean;
    onSort: (oldIndex: number, newIndex: number) => void;
    handleSortSave: () => void;
}

const ProjectResultsTab = (props: IProjectResultsTabProps) => {
    const {
        projectData,
        onTextChange,
        handleTextCustomFieldsChange,
        onImageChange,
        projectResults,
        projectResultsErrorState,
        handleSortSave,
        onUpload,
        onDelete,
        onReset,
        onSave,
        onImageCreate,
        loadingGallery,
    } = props;
    const [sortingActive, setSortingActive] = React.useState<boolean>(false);
    const toggleSortingAction = () => {
        if (sortingActive) {
            handleSortSave();
        }

        setSortingActive(!sortingActive);
    };
    const SortableItem = SortableElement(({ value, index }: { value: any; index: any }) => (
        <ProjectResultCard
            key={value.name + index}
            index={index}
            projectResultsErrorState={projectResultsErrorState}
            projectResult={value}
            onChange={onUpload}
            onDelete={onDelete}
            onReset={onReset}
            isSorting={sortingActive}
        />
    ));
    const SortableList = SortableContainer(({ items }: { items: IProjectResult[] }) => {
        return (
            <Grid container direction="row" spacing={2} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                {items ? (
                    items.map((image: IProjectResult, index) => (
                        <SortableItem value={image} index={index} key={image.id ? image.id + index + index : index} />
                    ))
                ) : (
                    <CircularProgress />
                )}
            </Grid>
        );
    });
    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        props.onSort(oldIndex, newIndex);
    };
    return (
        <>
            {loadingGallery && <LinearProgress color={"primary"} style={{ marginBottom: "16px" }} />}

            <Paper variant="outlined" className="main-paper">
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Ergebnisse Header
                    </Typography>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="body1" className="gap-bottom">
                        Wenn keine Daten im Headerbereich angegeben werden, werden die Einträge des Start Tabs geladen.
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <TextField
                        name="menuTabResults"
                        label="Menü Label"
                        fullWidth
                        value={projectData.menuTabResults}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TextField
                        name="titleTabResults"
                        label="Seiten Titel"
                        fullWidth
                        value={projectData.titleTabResults}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                    <TitleContainerSelectbox
                        selectedValue={projectData.customFields?.headerBgTabResults || ""}
                        name={"headerBgTabResults"}
                        setSelectedValue={handleTextCustomFieldsChange}
                    />
                    <TextField
                        name="descTabResults"
                        id="outlined-multiline-flexible"
                        label="Kurzbeschreibung"
                        fullWidth
                        multiline
                        value={projectData.descTabResults}
                        onChange={onTextChange}
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                    <ImageUpload
                        onChange={onImageChange}
                        docData={projectData.headerTabResults}
                        idKey={"headerTabResults"}
                        name={"headerTabResults"}
                        label={"Info Header"}
                        defaultFilename="Header.jpg"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h2" className="main-header gap-bottom">
                        Ergebnisse
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Button color="primary" onClick={onSave} startIcon={<IconSave />}>
                        Ergebnis speichern
                    </Button>

                    <Button color="secondary" onClick={onImageCreate} startIcon={<IconAdd />}>
                        Ergebnis hinzufügen
                    </Button>
                    <Button
                        color="secondary"
                        onClick={toggleSortingAction}
                        startIcon={sortingActive ? <IconOpenWith /> : <IconGames />}
                        // this is disabled that he can't do the sort till he fill the missing data or remove the empty created card
                        disabled={Boolean(projectResultsErrorState.length)}
                    >
                        {sortingActive ? "Sortierung deaktivieren" : "Sortierung aktivieren"}
                    </Button>
                </Grid>

                {sortingActive && (
                    <SortableList items={projectResults ? projectResults : []} onSortEnd={onSortEnd} axis="xy" />
                )}

                {!sortingActive && (
                    <Grid container direction="row" spacing={2} style={{ marginLeft: "-16px", marginRight: "-16px" }}>
                        {projectResults ? (
                            projectResults.map((projectResult: IProjectResult, index: number) => (
                                <ProjectResultCard
                                    key={index}
                                    index={index}
                                    projectResult={projectResult}
                                    projectResultsErrorState={projectResultsErrorState}
                                    onChange={onUpload}
                                    onDelete={onDelete}
                                    onReset={onReset}
                                />
                            ))
                        ) : (
                            <CircularProgress />
                        )}
                    </Grid>
                )}
            </Paper>
        </>
    );
};

export default ProjectResultsTab;
