import "./ProjectLongBlock.css";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import React from "react";
import { MapDispatchToPropsParam, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "typesafe-actions";

import { API_URL_STATIC, PAGE_BREAK_MOBILE } from "../../config/config";
import useWindowSize from "../../customhooks/useWindowSize";
import LoadCmsPageProjectSelectbox from "../../store/cmsPageProjectSelectbox/LoadCmsPageProjectSelectbox";
import IMandant from "../../types/IMandant";
import IProjectList from "../../types/project/IProjectList";
import { toPortalUrlFromProject } from "../../utils/toPortalUrl";

interface IOwnProps {
    mandant: IMandant;
    projectData: IProjectList;
    index: number;
}
interface IMapDispatchProps {
    dispatchLoadCmsPageProjectSelectbox: (mandantKey: string, projectId: string) => void;
}
interface ITabProjectProps extends IOwnProps, IMapDispatchProps {}

const ProjectLongBlock = (props: ITabProjectProps) => {
    const history = useHistory();

    const { projectData, index, mandant, dispatchLoadCmsPageProjectSelectbox } = props;

    const windowWidth = useWindowSize();
    const isMobileView = windowWidth < PAGE_BREAK_MOBILE;

    React.useEffect(() => {
        if (mandant.id && projectData.id) {
            dispatchLoadCmsPageProjectSelectbox(mandant.key, String(projectData.id));
        }
    }, [mandant, projectData, dispatchLoadCmsPageProjectSelectbox]);

    const getPadding = (entryIndex: number, isImage = false) => {
        if (entryIndex === 0) {
            return isImage ? "project-long-block__margin-image-right" : "project-long-block__padding-left";
        } else if (entryIndex % 2 === 0) {
            return isImage ? "project-long-block__margin-image-left" : "project-long-block__padding-right";
        } else {
            return isImage ? "project-long-block__margin-image-left" : "project-long-block__padding-right";
        }
    };

    return isMobileView ? (
        <>
            <div className="mandant_mobile-container">
                <div className={"mandant_mobile-title"}>
                    <Typography variant="h3" color="textSecondary">
                        {projectData.shortTitle}
                    </Typography>
                    <Typography variant="h2">{projectData.title}</Typography>
                </div>
                <div className={"mandant_mobile-image"}>
                    <img
                        src={API_URL_STATIC + projectData.imageMenu?.url}
                        alt={"mandant_mobile-image"}
                        title={projectData.title}
                    />
                </div>
                <div className={"mandant_mobile-description-container"}>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        component="p"
                        className="mandant_mobile-description"
                    >
                        {projectData.shortDesc}
                    </Typography>

                    {projectData.flagLinkExternalOnly && (
                        <Button color="primary" endIcon={<ArrowForwardIcon />} className={"project-long-block-btn"}>
                            <a
                                href={projectData.urlKey}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                            >
                                Mehr erfahren
                            </a>
                        </Button>
                    )}

                    {!projectData.flagLinkExternalOnly && (
                        <Button
                            color="primary"
                            href={toPortalUrlFromProject(mandant, "" + projectData.urlKey)}
                            onClick={() => {
                                history.push(toPortalUrlFromProject(mandant, "" + projectData.urlKey));
                            }}
                            endIcon={<ArrowForwardIcon />}
                            className={"project-long-block-btn"}
                        >
                            Mehr erfahren
                        </Button>
                    )}
                </div>
            </div>
        </>
    ) : (
        <Grid item className={"project-log-block__main-grid"}>
            <Card className={"project-log-block__card"}>
                <div
                    className={"project-log-block__details " + getPadding(index)}
                    style={{ order: index === 0 ? 1 : 2 }}
                >
                    <div className={"project-log-block__card-content"}>
                        <Typography
                            component="h3"
                            variant="h3"
                            color="textSecondary"
                            className={"project-log-block__first-title"}
                        >
                            {projectData.shortTitle}
                        </Typography>
                        <Typography component="h2" variant="h2" className={"project-log-block__second-title"}>
                            {projectData.title}
                        </Typography>
                        <Typography variant="body1" color="textSecondary" component="p">
                            {projectData.shortDesc}
                        </Typography>
                    </div>
                    <div className={"project-log-block__controls"}>
                        {projectData.flagLinkExternalOnly && (
                            <Button color="primary" endIcon={<ArrowForwardIcon />} className={"project-long-block-btn"}>
                                <a
                                    href={projectData.urlKey}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "white" }}
                                >
                                    Mehr erfahren
                                </a>
                            </Button>
                        )}
                        {!projectData.flagLinkExternalOnly && (
                            <Button
                                color="primary"
                                href={toPortalUrlFromProject(mandant, "" + projectData.urlKey)}
                                onClick={() => {
                                    history.push(toPortalUrlFromProject(mandant, "" + projectData.urlKey));
                                }}
                                endIcon={<ArrowForwardIcon />}
                                className={"project-long-block-btn"}
                            >
                                Mehr erfahren
                            </Button>
                        )}
                    </div>
                </div>

                <div
                    className={"project-log-block__image with-shadow " + getPadding(index, true)}
                    style={{ order: index === 0 ? 2 : 1 }}
                >
                    <img alt={"Projekt Header Bild"} src={API_URL_STATIC + projectData.imageMenu?.url}></img>
                </div>
            </Card>
        </Grid>
    );
};
const mapDispatchToProps: MapDispatchToPropsParam<IMapDispatchProps, IOwnProps> = (
    dispatch: ThunkDispatch<{}, {}, Action>
) => {
    return {
        dispatchLoadCmsPageProjectSelectbox: (mandantKey: string, projectId: string) => {
            return dispatch(LoadCmsPageProjectSelectbox(mandantKey, projectId));
        },
    };
};

export default connect(null, mapDispatchToProps)(ProjectLongBlock);
